<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" stripe multiple pagination max-items="20" search :data="officines">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown> -->
                <vs-button class="mb-4 md:mb-0" @click="$router.push('/apps/officine/officine-add')">Nouvelle officine</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>
                <vs-th sort-key="Code">
                  Code agence
                </vs-th>
                <vs-th sort-key="NomOffic">
                  Libelle
                </vs-th>
                <vs-th sort-key="RegimeOffic">
                  Regime
                </vs-th>
                <vs-th sort-key="Opticien">
                  Opticien
                </vs-th>
                <vs-th sort-key="Adresse.adresse">
                  Adresse
                </vs-th>
                <vs-th sort-key="Adresse.Tel">
                  Tel
                </vs-th>
                <vs-th sort-key="Adresse.Cel">
                  Cel
                </vs-th>
                <!-- <vs-th sort-key="Adresse.Fax">
                  Fax
                </vs-th> -->
                <vs-th sort-key="OfficineActive">
                 Status
                </vs-th>
                <vs-th sort-key="OfficinePrincipale">
                  Principale
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].Code">
                    {{data[indextr].Code}}
                  </vs-td>
                  <vs-td :data="data[indextr].NomOffic">
                    {{data[indextr].NomOffic}}
                  </vs-td>
                  <vs-td :data="data[indextr].RegimeOffic">
                    {{data[indextr].RegimeOffic}}
                  </vs-td>
                  <vs-td :data="data[indextr].Opticien">
                    {{data[indextr].Opticien}}
                  </vs-td>
                  <vs-td :data="data[indextr].Adresse">
                    {{data[indextr].Adresse.adresse}}
                  </vs-td>

                  <vs-td :data="data[indextr].Adresse.Tel">
                    {{data[indextr].Adresse.Tel}}
                  </vs-td>
                  <vs-td :data="data[indextr].Adresse.Cel">
                    {{data[indextr].Adresse.Cel}}
                  </vs-td>

                  <!-- <vs-td :data="data[indextr].Adresse.Fax">
                    {{data[indextr].Adresse.Fax}}
                  </vs-td> -->
                  <vs-td :data="data[indextr].OfficineActive">
                    <template v-if="data[indextr].OfficineActive == true">
                      <vs-chip transparent color="success">
                        active
                      </vs-chip>

                    </template>
                    <template v-else>
                      <vs-chip transparent color="danger">
                        inactive
                      </vs-chip>
                    </template>

                  </vs-td>

                  <vs-td :data="data[indextr].OfficinePrincipale">
                    <template v-if="data[indextr].OfficinePrincipale == true">
                      <vs-chip transparent color="success">
                        oui
                      </vs-chip>

                    </template>
                    <template v-else>
                      <vs-chip transparent color="danger">
                        non
                      </vs-chip>
                    </template>
                  </vs-td>

                  <vs-td :data="data[indextr]._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateOfficineShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: [],
      officineId: ''
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    updateOfficineShowForm (data) {
      this.$router.push(`/apps/officine/officine-edit/${data._id}`)
    },
    confirmDeleteRecord (data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${data._id}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord(data._id)
      })
    },
    deleteRecord (id) {
      this.$store.dispatch('officine/dropOfficine', id)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => {
          console.log(err)
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Suppression',
        text: 'Officine supprimer avec success'
      })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then((resp) => {
          console.log(resp)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
